<template>
  <div class="reset">
    <div class="header">
      <div class="header-logo">
        <img
          class="header-img"
          src="../../assets/images/login/logo1.png"
          alt=""
        />
        <div class="header-right">
          <div class="header-title">榆阳产业服务平台营销商城</div>
          <div class="header-subtitle">
            乡村振兴丨产业兴旺丨生态宜居丨乡风文明丨治理有效丨生活富裕
          </div>
        </div>
      </div>
    </div>
    <div class="header-line"></div>
    <div class="main">

      <div class="main-header">

      
        <div class="main-title">
          <img
            class="title-icon"
            src="../../assets/images/login/titleIcon.png"
            alt=""
          />
          <div class="title-text">免费开店</div>
          <img
            class="title-icon"
            src="../../assets/images/login/titleIcon.png"
            alt=""
          />
        </div>
        <div class="timeline-box">
          <div class="timeline-item">
            <div class="item-index s-item-index">
              <span v-if="type <= 1">1</span>
              <i v-if="type > 1" class="el-icon-check"></i>
            </div>
            <span class="item-text s-item-text">填写店铺信息</span>
          </div>
          <img
            class="main-timeline main-timeline1"
            src="../../assets/images/login/timeline.png"
            alt=""
          />
          <div class="timeline-item">
            <div :class="['item-index', type > 1 ? 's-item-index' : '']">
              <i v-if="type > 2" class="el-icon-check"></i>
              <span v-if="type <= 2">2</span>
            </div>
            <span :class="['item-text', type > 1 ? 's-item-text' : '']"
              >等待审核</span
            >
          </div>
          <img
            class="main-timeline main-timeline2"
            src="../../assets/images/login/timeline.png"
            alt=""
          />
          <div class="timeline-item main-timeline2">
            <div :class="['item-index', type > 2 ? 's-item-index' : '']">
              <span>3</span>
            </div>
            <span :class="['item-text', type > 2 ? 's-item-text' : '']"
              >审核结果</span
            >
          </div>
        </div>
      </div>
      <el-form
        v-if="type == 1"
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="店铺性质">
          <div class="nature-box">
              <div class="nature-itme">
                  <div class="nature-title">个体店铺</div>
                  <div class="nature-main">
                      <div class="nature-main-item" v-for="(item,index) in individual" :key="index">
                          <el-radio v-model="form.shopNature" :label="item.lable">{{item.name}}</el-radio>
                      </div>
                        
                  </div>
              </div>
              <div>
                  <div class="nature-title">企业店铺</div>
                  <div class="nature-main">
                      <div class="nature-main-item" v-for="(item,index) in enterprise" :key="index">
                        <el-radio v-model="form.shopNature" :label="item.lable">{{item.name}}</el-radio>
                      </div>
                        
                  </div>
              </div>
          </div>
        </el-form-item>
        <el-form-item label="店铺名称" prop="shopName">
          <el-input v-model="form.shopName"></el-input>
        </el-form-item>
        <el-form-item label="经营范围" prop="businessScope">
          <el-input v-model="form.businessScope"></el-input>
        </el-form-item>
        <el-form-item v-if="form.shopNature==1" label="营业执照">
          <div class="upload-box">
            <el-upload
                class="avatar-uploader"
                :action="onload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                name="files"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.businessLicenseUrl" :src="form.businessLicenseUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item v-if="form.shopNature==2 || form.shopNature==3 || form.shopNature==4 || form.shopNature==5" label="营业执照">
          <div class="upload-box">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.businessLicenseUrl1" :src="form.businessLicenseUrl1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>三证合一</span>
            </div>
            <div v-if="form.shopNature == 3 || form.shopNature == 4 || form.shopNature == 5">
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess3"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.businessLicenseUrl2" :src="form.businessLicenseUrl2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>商标注册证</span>
            </div>
            <div v-if="form.shopNature == 3">
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess4"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.businessLicenseUrl3" :src="form.businessLicenseUrl3" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>一级授权书</span>
            </div>
            <div v-if="form.shopNature == 4">
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess5"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.businessLicenseUrl4" :src="form.businessLicenseUrl4" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>二级授权书</span>
            </div>
            <div v-if="form.shopNature == 5">
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess6"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.businessLicenseUrl5" :src="form.businessLicenseUrl5" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>四级授权书</span>
            </div>

          </div>
        </el-form-item>
        <el-form-item v-if="form.shopNature==3 || form.shopNature==4 || form.shopNature==5" label="法人身份证照">
          <div class="upload-box">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess7"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.corporateIdPhotoUrl1" :src="form.corporateIdPhotoUrl1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>正面</span>
            </div>
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess8"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.corporateIdPhotoUrl2" :src="form.corporateIdPhotoUrl2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>反面</span>
            </div>
            <div class="placeholder"></div>
          </div>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 0 || form.shopNature == 1" label="店主姓名" prop="shopkeeperName">
          <el-input v-model="form.shopkeeperName"></el-input>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 2 || form.shopNature == 3 || form.shopNature == 4 || form.shopNature == 5" label="管理员姓名" prop="administratorName">
          <el-input v-model="form.administratorName"></el-input>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 0 || form.shopNature == 1 " label="手机号码" prop="shopkeeperPhone">
          <el-input v-model="form.shopkeeperPhone"></el-input>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 2 || form.shopNature == 3 || form.shopNature == 4 || form.shopNature == 5" label="手机号码" prop="administratorPhone">
          <el-input v-model="form.administratorPhone"></el-input>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 0 || form.shopNature == 1 " label="身份证照">
          <div class="upload-box">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess9"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.shopkeeperIdPhotoUrl1" :src="form.shopkeeperIdPhotoUrl1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>正面</span>
            </div>
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess10"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.shopkeeperIdPhotoUrl2" :src="form.shopkeeperIdPhotoUrl2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>反面</span>
            </div>
            <div class="placeholder"></div>
          </div>
        </el-form-item>
        <el-form-item v-if="form.shopNature == 2 || form.shopNature == 3 || form.shopNature == 4 || form.shopNature == 5" label="管理员身份证照">
          <div class="upload-box">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess11"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.administratorIdPhotoUrl1" :src="form.administratorIdPhotoUrl1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>正面</span>
            </div>
            <div>
              <el-upload
                  style="margin-left: 15px;"
                  class="avatar-uploader"
                  :action="onload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess12"
                  name="files"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.administratorIdPhotoUrl2" :src="form.administratorIdPhotoUrl2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>反面</span>
            </div>
            <div class="placeholder"></div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" @click="submitForm('ruleForm')">确认提交</el-button>
          <div class="step-bottom">
            <div></div>
            <div class="already-box" @click="already">
                <span>返回登录</span>
                <img class="already-img" src="../../assets/images/login/already.png" alt="">
            </div>
        </div>
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>

      <div class="wait" v-if="type == 2">
        <img class="wait-icon" src="../../assets/images/login/success.png" alt="">
        <div class="wait-text">信息已提交</div>
        <div class="wait-text">预计1-3天完成审核</div>
      </div>


      <div class="wait" v-if="type == 3">
        <div> 
          <img class="wait-icon" src="../../assets/images/login/success.png" alt="">
          <div class="wait-text">审核已通过</div>
          <div class="wait-text">等您可以登录您的店铺啦</div>
          <div class="wait-btn">返回登录</div>
        </div>
      </div>
      <div class="wait" v-if="type == 4">
        <div> 
            <img class="wait-icon" src="../../assets/images/login/err.png" alt="">
            <div class="wait-text">审核未通过</div>
            <div class="wait-text" style="cursor: pointer;" @click="reasonClick">点击查看 <span>驳回原因</span> </div>
            <div class="wait-btn" @click="againClick" >重新申请信息</div>
        </div>
      </div>

    </div>

    <div class="footer">
        <div class="main-type-box">
                <span class="footer-type">关于我们</span>
                <div class="main-line main-line3"></div>
                <span class="footer-type">联系我们</span>
        </div>
        <div class="footer-title">
            <span>Copyright©2004-2021</span>
            <span> 蜂窝FW.com 版权所有</span>  
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reset",
  data() {
    return {
        reason: '', //审核原因
        approvalStatus: '', // 0待审核 1已通过 2已驳回
        reviewType: 0,//审核成功0  审核失败1
        imageUrl: '',
        imageUrl2: '',
        onload: '/commodity/fileUpload/upload',
        type: this.$route.query.type,
        radio:'1',
        ruleForm: {
            name: '',
            region: '',
            date1: '',
            date2: '',
            delivery: false,
            type: [],
            resource: '',
            desc: '',
        },
        form: {
          /**
          * 店铺性质 0：个人店铺 1：个体工商户 2：普通店铺 3：旗舰店 4：专卖店 5：专营店
          */
          shopNature: '0',
          /**
          * 店铺名称
          */
          shopName: '',
          /**
          * 经营范围
          */
          businessScope: '',
          /**
          * 营业执照-个体工商户
          */
          businessLicenseUrl: '',
          /**
           * 营业执照-三证合一
           */
          businessLicenseUrl1: '',
          /**
           * 营业执照-商标注册证
           */
          businessLicenseUrl2: '',
          /**
           * 营业执照-一级独占授权书
           */
          businessLicenseUrl3: '',
          /**
           * 营业执照-≥二级授权书
           */
          businessLicenseUrl4: '',
          /**
           * 营业执照-≥四级授权书
           */
          businessLicenseUrl5: '',
          /**
           * 法人身份证照正面
           */
          corporateIdPhotoUrl1: '',
          /**
           * 法人身份证照反面
           */
          corporateIdPhotoUrl2: '',
          /**
          * 管理员姓名
          */
          administratorName: '',
          /**
          * 管理员手机号码
          */
          administratorPhone: '',
          /**
          * 管理员身份证照正面
          */
          administratorIdPhotoUrl1: '',
          /**
           * 管理员身份证照反面
           */
          administratorIdPhotoUrl2: '',
          /**
           * 店主姓名
           */
          shopkeeperName: '',
          /**
           * 店主电话
           */
          shopkeeperPhone: '',
          /**
           * 店主身份证照正面
           */
          shopkeeperIdPhotoUrl1: '',
          /**
           * 店主身份证照反面
           */
          shopkeeperIdPhotoUrl2: ''
        },
        individual: [ // 个体
            {
                name : '个人店铺',
                lable: '0'
            }, {
                name : '个体工商户',
                lable: '1'
            }
        ],
        enterprise: [{   // 企业店铺
            name : '普通店铺',
            lable: '2'
        }, {
            name : '旗舰店',
            lable: '3'
        }, {
            name : '专卖店',
            lable: '4'
        }, {
            name : '专营店',
            lable: '5'
        }],
        rules: {
          shopName: [
            { required: true, message: '店铺名称不能为空', trigger: 'blur' },
          ],
          businessScope: [
            { required: true, message: '经营范围不能为空', trigger: 'change' }
          ],
          administratorName: [
            { required: true, message: '管理员姓名不能为空', trigger: 'change' }
          ],
          administratorPhone: [
            { required: true, message: '管理员手机号不能为空', trigger: 'change' }
          ],
          shopkeeperName: [
            { required: true, message: '店主姓名不能为空', trigger: 'change' }
          ],
          shopkeeperPhone: [
            { required: true, message: '店主手机号不能为空', trigger: 'change' }
          ],
        }
    };
  },
  mounted() {
    if( this.type == '4' ) {
      this.isApprovalStatus() 
    }
  },
  
  methods: {
    againClick() {
      this.qa.logicDeleteShop({isDelete: 1, id: this.form.id}).then(res => {
          this.type = '1'
        })
    },
      isApprovalStatus() {
        this.qa.queryShop({}).then(res => {
          console.log(res.data)
          this.reason = res.data.approvalRemark
          this.form = res.data
          this.form.shopNature = res.data.shopNature + ''
        })
      },
      reasonClick() {
        console.log(this.reason)
          this.$alert( this.reason, '驳回原因', {
            confirmButtonText: '确定',
          });
        
      },
      validFile(){
          if(this.form.shopNature == "0"){
            if(this.form.shopkeeperIdPhotoUrl1 == "" || this.form.shopkeeperIdPhotoUrl2 == ""){
              this.$message.error("请上传身份证正反面照片！");
              return false;
            }
          }
          if(this.form.shopNature == "1"){
            if(this.form.businessLicenseUrl == ""){
              this.$message.error("请上传营业执照！");
              return false;
            }
            if(this.form.shopkeeperIdPhotoUrl1 == "" || this.form.shopkeeperIdPhotoUrl2 == ""){
              this.$message.error("请上传身份证正反面照片！");
              return false;
            }
          }


          if(this.form.shopNature == "2"){
            if(this.form.businessLicenseUrl1 == ""){
              this.$message.error("请上传营业执照！");
              return false;
            }
            if(this.form.administratorIdPhotoUrl1 == "" || this.form.administratorIdPhotoUrl2 == ""){
              this.$message.error("请上传管理员身份证正反面照片！");
              return false;
            }
          }


          if(this.form.shopNature == "3"){
            if(this.form.businessLicenseUrl1 == ""){
              this.$message.error("请上传营业执照(三证合一)！");
              return false;
            }
            if(this.form.businessLicenseUrl2 == ""){
              this.$message.error("请上传商标注册证！");
              return false;
            }
            if(this.form.businessLicenseUrl3 == ""){
              this.$message.error("请上传一级授权书！");
              return false;
            }
            if(this.form.corporateIdPhotoUrl1 == "" || this.form.corporateIdPhotoUrl2 == ""){
              this.$message.error("请上传法人身份证正反面照片！");
              return false;
            } 
            if(this.form.administratorIdPhotoUrl1 == "" || this.form.administratorIdPhotoUrl2 == ""){
              this.$message.error("请上传管理员身份证正反面照片！");
              return false;
            }
          }


          if(this.form.shopNature == "4"){
            if(this.form.businessLicenseUrl1 == ""){
              this.$message.error("请上传营业执照(三证合一)！");
              return false;
            }
            if(this.form.businessLicenseUrl2 == ""){
              this.$message.error("请上传商标注册证！");
              return false;
            }
            if(this.form.businessLicenseUrl4 == ""){
              this.$message.error("请上传二级授权书！");
              return false;
            }
            if(this.form.corporateIdPhotoUrl1 == "" || this.form.corporateIdPhotoUrl2 == ""){
              this.$message.error("请上传法人身份证正反面照片！");
              return false;
            } 
            if(this.form.administratorIdPhotoUrl1 == "" || this.form.administratorIdPhotoUrl2 == ""){
              this.$message.error("请上传管理员身份证正反面照片！");
              return false;
            }
          }


          if(this.form.shopNature == "5"){
            if(this.form.businessLicenseUrl1 == ""){
              this.$message.error("请上传营业执照(三证合一)！");
              return false;
            }
            if(this.form.businessLicenseUrl2 == ""){
              this.$message.error("请上传商标注册证！");
              return false;
            }
            if(this.form.businessLicenseUrl5 == ""){
              this.$message.error("请上传四级授权书！");
              return false;
            }
            if(this.form.corporateIdPhotoUrl1 == "" || this.form.corporateIdPhotoUrl2 == ""){
              this.$message.error("请上传法人身份证正反面照片！");
              return false;
            } 
            if(this.form.administratorIdPhotoUrl1 == "" || this.form.administratorIdPhotoUrl2 == ""){
              this.$message.error("请上传管理员身份证正反面照片！");
              return false;
            }
          }
      }, 
      submitForm(formName) {
            if(this.validFile() == false){
              return false;
            }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            if( this.type == 1 ) {
              this.qa.createShop(this.form).then(res => {
                if(res.respCode == "0000") {
                  this.$message({
                    message: '店铺已提交审核，预计1-3个工作日审核结束',
                    type: 'success'
                  });
                  setTimeout(() => {
                    this.type = 2
                  },1500)
                } else {
                    this.$message.error(res.respDesc);
                }
              })
              
            }else if( this.type = 2 ){
              this.type = 3
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      already () {
        this.$router.push({
          name: 'login'
        })
      },
      handleAvatarSuccess1(e){
        this.form.businessLicenseUrl = e.data.fileList[0].url;
      },
      handleAvatarSuccess2(e){
        this.form.businessLicenseUrl1 = e.data.fileList[0].url;
      },
      handleAvatarSuccess3(e){
        this.form.businessLicenseUrl2 = e.data.fileList[0].url;
      },
      handleAvatarSuccess4(e){
        this.form.businessLicenseUrl3 = e.data.fileList[0].url;
      },
      handleAvatarSuccess5(e){
        this.form.businessLicenseUrl4 = e.data.fileList[0].url;
      },
      handleAvatarSuccess6(e){
        this.form.businessLicenseUrl5 = e.data.fileList[0].url;
      },
      handleAvatarSuccess7(e){
        this.form.corporateIdPhotoUrl1 = e.data.fileList[0].url;
      },
      handleAvatarSuccess8(e){
        this.form.corporateIdPhotoUrl2 = e.data.fileList[0].url;
      },
      handleAvatarSuccess9(e){
        this.form.shopkeeperIdPhotoUrl1 = e.data.fileList[0].url;
      },
      handleAvatarSuccess10(e){
        this.form.shopkeeperIdPhotoUrl2 = e.data.fileList[0].url;
      },
      handleAvatarSuccess11(e){
        this.form.administratorIdPhotoUrl1 = e.data.fileList[0].url;
      },
      handleAvatarSuccess12(e){
        this.form.administratorIdPhotoUrl2 = e.data.fileList[0].url;
      },

      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
      }
  },
};
</script>
<style scoped src="./css/applicationShop.css">
  .submit-btn1 {
    margin: 0 auto;
    
  }
 .el-button--primary {
    background-color: #FF9954 !important;
    border-color: #FF9954 !important;
  }
</style>